<template>
    <vs-button :loading="loading" transparent block square class="searchsuggestion-container">
        <img v-if="poi.isCity || poi.isRegion || poi.isCountry" src="../assets/icons/city.png" style="margin-left: 2px; width: 24px; height: auto;"/>
        <img v-else src="../assets/icons/location.png" style="margin-left: 2px; width: 24px;"/>
        <div class="content" v-if="poi.isCity"><!-- IS A CITY-->
            <p class="bold">{{ poi != null ? poi.name : ''}}</p>
            <p class="light" style="font-size: 12px;">{{ $t('common.city') }}</p>
        </div>
        <div class="content" v-else-if="poi.isRegion"><!-- IS A REGION-->
            <p class="bold">{{ poi != null ? poi.name : ''}}</p>
            <p class="light" style="font-size: 12px;">{{ $t('common.region') }}</p>
        </div>
        <div class="content" v-else-if="poi.isCountry"><!-- IS A COUNTRY-->
            <p class="bold">{{ poi != null ? poi.name : ''}}</p>
            <p class="light" style="font-size: 12px;">{{ $t('common.country') }}</p>
        </div>
        <div class="content" v-else><!-- IS A POI -->
            <p class="bold">{{ poiDetails != null ? poiDetails.name : ''}}</p>
            <p class="light" style="font-size: 12px;">{{ poiDetails != null ? poiDetails.street : ''}}</p>
        </div>
    </vs-button>

</template>

<script>
import { apiCall } from '../utils/ApiMiddleware';
export default {
    name: 'searchSuggestion',
    props: {
        poi: Object
    },

    data() {
        return {
            loading: false,
            poiDetails: null
        }
    },

    mounted(){
        if(this.poi.latitude != undefined){
            this.getPoi(this.poi.id);
        }        
    },

    methods: {
        async getPoi(id) {
            this.loading = true;
            const response = await apiCall('GET', '/pois/' + id);
            this.loading = false;
            if (response.status == 200) {
                this.poiDetails = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.search.cannotLoadSuggestion'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },
    }
}
</script>

<style scoped>
.searchsuggestion-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background: white;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

p {
    margin: 0;
}
</style>

<style>
.searchsuggestion-container .vs-button__content {
    justify-content: flex-start;
    gap: 10px;
}
.searchsuggestion-container.vs-button {
    margin: 0!important;
}
</style>