<template>
<div class="searchfilter-container">
    <h3>Filtri</h3>
    <div class="actions">
        <vs-button transparent @click="close()">{{ $t('common.cancel') }}</vs-button>
        <vs-button transparent @click="resetFilters">{{ $t('common.reset') }}</vs-button>
    </div>
    <div class="fields" v-if="filters != null">

        <template v-if="!auto">
            <p>{{ $t('common.region') }}</p>
            <LazyDropdown v-model="filters.state_id" :value="filters.state_id" endpoint="/places/states" />

            <div class="divider"></div>
            <p>{{ $t('common.city') }}</p>
            <LazyDropdown v-model="filters.city_id" :value="filters.city_id" endpoint="/places/cities" />

            <div class="divider"></div>
        </template>
        <p style="margin-bottom: 10px!important;">{{ $t('common.selectTarget') }}</p>
        <LazyDropdown v-model="filters.target_ids" :value="filters.target_ids" endpoint="/targets" :multiple="true" :placeholder="$t('common.categories')" />

        <div class="divider" style="margin-bottom: 10px;"></div>

        <vs-checkbox block v-model="filters.only_place_to_discover" style="margin-bottom: 10px;">{{ $t('explore.placeToDiscover') }}</vs-checkbox>

        <vs-tooltip v-model="upgradeTooltip" not-hover not-arrow bottom border color="#EF7E18" style="width: 100%; padding: 0; margin: 0;" v-if="user != null">
            <vs-checkbox @click="premiumMiddleware" class="myplaces" block v-model="filters.only_my_places">

                {{ $t('explore.myPlaces') }}

                <template #icon v-if="filters.only_my_places">
                    <img src="../assets/icons/pin_white.png" style="width: 20px;" />
                </template>
                <template #icon v-else>
                    <img src="../assets/icons/pin_grey.png" style="width: 20px; opacity: 0.5;" />
                </template>

            </vs-checkbox>
            <template #tooltip>
                <div class="content-tooltip">
                    <h4 class="center">
                        {{ $t('common.thisFeatureIsOnlyAvailableInPremiumVersion') }}
                    </h4>
                    <footer>
                        <vs-button block size="large" @click="upgrade" :loading="upgradeLoading">
                            {{ $t('profile.subscription.upgrade') }}
                        </vs-button>
                    </footer>
                </div>
            </template>
        </vs-tooltip>
        <div class="divider" style="margin-bottom: 10px; margin-top: 10px;"></div>

        <p style="margin-bottom: 5px;">{{ $t('reviews.userEvaluation') }}</p>
        <div class="ratings">
            <vs-button size="large" :border="!filters.ratings.includes(i)" :dark="!filters.ratings.includes(i)" @click="toggleRating(i)" v-for="i in 5" :key="'rating-' + i">
                {{ i }}
                <img class="inactive" src="../assets/icons/search/rating.png" style="width: 18px;" />
                <img class="active" src="../assets/icons/search/rating-white.png" style="width: 18px;" />
            </vs-button>
        </div>

        <vs-button size="large" style="margin-top: 20px;" @click="applyFilters">{{ $t('explore.applyFilters') }}</vs-button>
    </div>
</div>
</template>

<script>
import {
    apiCall
} from '../utils/ApiMiddleware';
import LazyDropdown from './LazyDropdown.vue';
export default {
    name: 'searchFilters',
    props: {
        change: Function,
        close: Function,
        oldfilters: {
            type: Object,
            default: () => {}
        },
        auto: { // load suggestions
            type: Boolean,
            default: true
        },

        user: {
            type: Object,
            default: null
        }
    },
    components: {
        LazyDropdown
    },
    data() {
        return {
            filters: null,
            targets: [],
            upgradeLoading: false,
            upgradeTooltip: false
        }
    },

    mounted() {
        if (this.oldfilters != null) {
            this.filters = JSON.parse(JSON.stringify(this.oldfilters));
        }

        this.getTargets();

        console.log(this.user)
    },

    methods: {

        upgrade() {
            this.upgradeLoading = true;
            setTimeout(() => {
                this.upgradeLoading = false;
                this.upgradeTooltip = false;
                this.close();
                this.$router.push({name: 'Sottoscrizione'})
            }, 1000);
        },

        premiumMiddleware(e){
            // user.subscription == null && !user.is_partner && !user.is_admin
            if(this.user.subscription == null && !this.user.is_partner && !this.user.is_admin){
                this.upgradeTooltip = true;
                e.preventDefault();
            }
        },

        resetFilters() {
            this.filters = {
                state_id: null,
                city_id: null,
                target_ids: [],
                only_place_to_discover: false,
                only_my_places: false,
                ratings: [],
            }
            this.filters.__ob__.dep.notify();
        },

        async getTargets() {
            const response = await apiCall('GET', '/targets');
            if (response.status == 200) {
                this.targets = response.data.data;
            }
        },

        toggleRating(i) {
            if (this.filters.ratings.includes(i)) {
                this.filters.ratings = this.filters.ratings.filter(e => e != i); // remove it
            } else {
                this.filters.ratings.push(i)
            }
        },

        applyFilters() {
            this.change(this.filters);
        },
    }
}
</script>

<style scoped>
p {
    margin: 0;
}


.content-tooltip {
    padding: 10px;
    box-sizing: border-box;
}

.content-tooltip button {
    margin: 0;
    margin-bottom: 5px;
}

.searchfilter-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background: white;
    gap: 5px;
    padding-bottom: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden !important;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

}

.ratings {
    display: flex;
    gap: 5px;
}

.divider {
    margin-top: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.fields {
    padding-left: 13px;
    padding-right: 13px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

h3 {
    margin: 0;
    margin-top: 15px;
}
</style><style>
.searchfilter-container .vs-button--border:before {
    border: 1px solid var(--grey-75);
}

.searchfilter-container .vs-button--border {
    background: transparent !important;
    color: var(--grey-75) !important;
}

.searchfilter-container .vs-button {
    margin: 0;
}

.searchfilter-container .vs-button--border img:not(.inactive) {
    display: none;
}

.searchfilter-container .vs-button--primary img:not(.active) {
    display: none;
}

.searchfilter-container .vs__dropdown-toggle {
    border: 1px solid var(--grey-75) !important;
}

.searchfilter-container input.vs__search::placeholder {
    color: #747474 !important;
    font-size: 15px !important;
}

.searchfilter-container .vs-checkbox-con {
    width: 30px !important;
    height: 30px !important;
}

.disabled-checkbox {
    pointer-events: none !important;
}

/* only from desktop */
@media (min-width: 768px) {
    .searchfilter-container .v-select.vs--multiple {
        max-width: 319px!important;
    }
}
</style>
