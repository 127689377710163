<template>
<div>
    <vs-dialog :blur="blur" v-model="visible" v-if="!isMobile" :prevent-close="preventClose">
        <component :is="component" :callback="callbackMiddleware" :data="data"/>
    </vs-dialog>
    <slideout-panel v-else></slideout-panel>
</div>
</template>

<script>
import {
    GlobalEventEmitter
} from '@/utils/GlobalEventEmitter'

export default {
    name: 'dialogWrapper',
    props: {
        component: Object,
        isMobile: Boolean,
        callback: Function,
        data: Object,
        preventClose: {
            type: Boolean,
            default: false
        },
        blur: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            visible: false,
            mobilePanel: null
        }
    },

    mounted() {
        setTimeout(() => {
            this.visible = true;
            this.togglePanel()
        }, 200)
        document.body.style.overflow = 'hidden';

    },

    beforeDestroy(){
        document.body.style.overflow = 'auto';
    },

    methods: {
        async callbackMiddleware(result, dismiss = false) { // se dismiss = true chiude il modal, poi chiama il callback esterno passando solo result
            if (dismiss) {
                this.visible = false;
                await this.togglePanel(); // se devo chiuderlo chiudo/nascondo
            }

            if (this.callback != null) { // se definito
                this.callback(result);
            }

        },
        async togglePanel() {
            var vm = this;
            if (!this.isMobile) {
                return;
            }

            if (this.visible) {
                this.mobilePanel = await this.$showPanel({
                    component: this.component,
                    openOn: 'bottom',
                    props: {
                        callback: this.callbackMiddleware,
                        data: this.data
                    }
                });

                document.getElementById('slide-out-panel-' + this.mobilePanel._id).className += ' mobile-dialog-padding'
                this.mobilePanel.promise
                    .then(result => {
                        console.log(result)
                        vm.visible = false

                    });
            } else if (this.mobilePanel != null) {
                await this.mobilePanel.hide();
                this.mobilePanel = null;
            }

        }
    },

    watch: {
        visible(n) {
            if (!n) {
                GlobalEventEmitter.$emit('hideDialog')
                this.$emit('close')
            }
        }
    }
}
</script>

<style>
.mobile-dialog-padding {
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    z-index: 2000 !important;

}
</style>
