import { render, staticRenderFns } from "./EditPoi.vue?vue&type=template&id=f48d4402&scoped=true&"
import script from "./EditPoi.vue?vue&type=script&lang=js&"
export * from "./EditPoi.vue?vue&type=script&lang=js&"
import style0 from "./EditPoi.vue?vue&type=style&index=0&id=f48d4402&prod&scoped=true&lang=css&"
import style1 from "./EditPoi.vue?vue&type=style&index=1&id=f48d4402&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f48d4402",
  null
  
)

export default component.exports